<script setup lang="ts">
import { ref } from 'vue';
import { add, format } from 'date-fns';

interface Props {
  open: boolean
  minRange: number
  maxRange: number | null
  disabledDates: Date[]
}

defineProps<Props>();

const bookingRange = ref();

const emit = defineEmits<{
  (e: 'updateRange', value: Date[]): void,
  (e: 'close'): void
}>();

const minimumDate = add(new Date(), { days: 2 });

function handleRange(val:Date[]) {
  emit('updateRange', val);
}
</script>
<template>
  <base-modal
    :open="open"
    @close="$emit('close')"
  >
    <div class="flex w-full flex-col">
      <p class="font-bold text-z-gray-900">
        Selecciona la fecha
      </p>
      <p class="mt-2 text-z-gray-800">
        Los precios no incluyen impuestos ni tarifas.
      </p>
      <div class="h-[376px]">
        <VueDatePicker
          v-model="bookingRange"
          inline
          :range="{
            minRange: minRange,
            maxRange: maxRange,
            noDisabledRange: true

          }"
          no-today
          locale="es"
          :enable-time-picker="false"
          :min-date="minimumDate"
          :disabled-dates="disabledDates"
          :day-names="['L', 'M', 'M', 'J','V','S','D']"
          class="mt-4"
          vertical
          @update:model-value="(val:Date[]) => handleRange(val)"
        >
          <template #month-year="{month, year}">
            <p class="ml-3">
              <span class="capitalize">{{ format(new Date().setMonth(month), 'MMMM') }}</span> de {{ year }}
            </p>
          </template>
          <template #action-row="{ selectDate }">
            <div class="fixed bottom-0 left-0 w-full px-6 pb-6">
              <base-button
                class="mt-4 w-full"
                @click="selectDate"
              >
                Seleccionar fecha
              </base-button>
            </div>
          </template>
        </VueDatePicker>
      </div>
    </div>
  </base-modal>
</template>
<style>
.dp__menu {
  border: 0 !important;
}
.dp__theme_light {
  --dp-primary-color: #00a48f;
  --dp-range-between-dates-background-color: var(--dp-primary-color);
  --dp-range-between-dates-text-color: var(--dp-primary-text-color);
  --dp-text-color: #202020;
  --dp-disabled-color-text: #9f9f9f;
  --dp-border-color: #f2f2f2;
}
:root {
  --dp-cell-border-radius: 100px;
  --dp-font-family: 'Poppins', sans-serif;
  --dp-row-margin: 8px 0;
  --dp-cell-size: 100%;
  --dp-menu-padding: 0;
  --dp-action-row-padding: 0;
}
.dp__calendar_row {
  height: 32px;
}
.dp__calendar_item {
  height: 32px;
  width: 100%;
}
.dp__cell_inner {
  border: 0px;
}
.dp__range_start {
  border-end-start-radius: 100px;
  border-start-start-radius: 100px;
}
.dp__range_end {
  border-end-end-radius: 100px;
  border-start-end-radius: 100px;
}
.dp__calendar_header {
  font-weight: normal;
  height: 32px;
}
</style>
