<script setup lang="ts">
import productGrid from './product-grid.vue';

interface Props {
  products: Product[]
}
defineProps<Props>();

const searchTerms = new URLSearchParams(window.location.search).get('q');
</script>
<template>
  <div class="m-10 flex max-h-fit">
    <div class="mt-2">
      <p class="ml-12 text-3xl">
        Mostrando resultados de búsqueda "{{ searchTerms }}"
      </p>
      <hr class="ml-10 mt-5 border-gray-400">
      <product-grid
        :products="products"
      />
    </div>
  </div>
</template>
