<script setup lang="ts">
import { camelCase } from 'lodash';
import { formatDate } from '@/utils/format-date';
import { ref } from 'vue';
import partnerDetailsModal from '../partner-details-modal.vue';

const BOOKED_STATUSES = ['paid', 'partner_cancelled', 'client_cancelled'];

interface Props {
  productBooking: ProductBooking;
  showMore?: boolean
  accountType: 'partner' | 'client'
}

defineProps<Props>();

const showPartnerDetailsModal = ref(false);
function togglePartnerDetailsModal() {
  showPartnerDetailsModal.value = !showPartnerDetailsModal.value;
}
</script>
<template>
  <div class="flex flex-col py-4">
    <div
      class="flex"
      :class="BOOKED_STATUSES.includes(productBooking.currentStatus) ? 'justify-between' : 'justify-end'"
    >
      <template v-if="productBooking.currentStatus === 'paid'">
        <div
          v-if="productBooking.canceled"
          class="flex space-x-1 self-start rounded bg-z-cerise-200 px-2 py-0.5 text-center text-xs text-z-cerise-600"
        >
          <img src="@/assets/icons/warning-alt.svg">
          <p>
            Cancelado
          </p>
        </div>
        <div
          v-else
          class="flex space-x-1 self-start rounded bg-z-turquoise-50 px-2 py-0.5 text-center text-xs text-z-turquoise-600"
        >
          <img src="@/assets/icons/check-circle-alt.svg">
          <p>
            Aceptado
          </p>
        </div>
      </template>
      <p class="w-fit self-end rounded bg-z-turquoise-50 px-2 py-0.5 text-center text-xs text-z-turquoise-600">
        {{ $t(`product.condition.${camelCase(productBooking.product.condition)}`) }}
      </p>
    </div>
    <div class="flex space-x-4 pt-3">
      <img
        :src="productBooking.productPictures[0].picture.webpSm.url"
        class="h-[72px] w-[72px] rounded-lg border border-z-gray-50 object-cover"
      >
      <div class="flex w-full flex-col">
        <p class="line-clamp-1 font-medium">
          {{ productBooking.product.name }}
        </p>
        <p class="mt-1 text-xs text-z-gray-800">
          {{ formatDate(productBooking.startDate) }} - {{ formatDate(productBooking.endDate) }}
        </p>
        <a
          v-if="showMore"
          class="mt-1 text-xs text-z-gray-800 underline"
          :href="`/perfil/productos/${productBooking.product.id}`"
        >
          Ver más
        </a>
        <div class="mt-3 flex w-full items-center">
          <div
            v-if="accountType === 'partner'"
            class="flex items-center"
          >
            <img
              :src="productBooking.clientAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <p class="ml-2 text-xs">
              {{ productBooking.currentStatus === 'awaiting_approval' ? 'Solicitado' : 'Reservado' }}
              por
              {{ productBooking.clientAccount.firstName }}
            </p>
          </div>
          <button
            v-else
            class="flex items-center"
            @click="togglePartnerDetailsModal"
          >
            <img
              :src="productBooking.partnerAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <p class="ml-2 text-xs">
              Dueño:
              {{ productBooking.partnerAccount.firstName }}
            </p>
          </button>
          <a
            class="ml-1"
            :href="`/chats/${productBooking.chatId}`"
          >
            <img
              src="@/assets/icons/chat-active.svg"
              class="h-5"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
  <partner-details-modal
    v-if="showPartnerDetailsModal"
    :open="showPartnerDetailsModal"
    :partner-account="productBooking.partnerAccount"
    :ratings="[]"
    @close="togglePartnerDetailsModal"
  />
</template>
