<script setup lang="ts">
import { isEmpty } from 'lodash';
import { ref, watch, onMounted } from 'vue';
import { string, object, number } from 'yup';
import { useStore } from '@/store/store';
import * as subcategoriesApi from '../api/subcategories';
import type { ProductFormData } from '../api/products';

interface Props {
  categories: Category[];
  summaryMode?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'continue', value: Partial<ProductFormData>): void
}>();

const store = useStore();

const subcategories = ref([]);
const categoryId = ref(store.productFormData?.categoryId ?? 0);
const subcategoryId = ref(store.productFormData?.subcategoryId ?? 0);
const brand = ref(store.productFormData?.brand ?? '');
const model = ref(store.productFormData?.model ?? '');
const condition = ref(store.productFormData?.condition ?? '0');
const weight = ref(store.productFormData?.weight ?? '0');

const conditionOptions = [
  { id: 'is_new', name: 'Nuevo' },
  { id: 'like_new', name: 'Semi nuevo' },
  { id: 'used', name: 'Usado' },
  { id: 'very_used', name: 'Muy usado' },
];

const weightOptions = [
  { id: 'lt5', name: 'Menos de 5 kg' },
  { id: 'gt5_lt10', name: 'Entre 5 y 10 kg' },
  { id: 'gt10_lt20', name: 'Entre 10 y 20 kg' },
  { id: 'gt20', name: 'Más de 20 kg' },
];

const formRules = object().shape({
  brand: string().required(),
  model: string().optional(),
  condition: string().required('Debes seleccionar un estado')
    .oneOf(conditionOptions.map((el) => el.id), 'Debes seleccionar un estado'),
  subcategory: number().required('Debes seleccionar una subcategoría')
    .moreThan(0, 'Debes seleccionar una subcategoría'),
  category: number().required('Debes seleccionar una categoría')
    .moreThan(0, 'Debes seleccionar una categoría'),
  weight: string().required('Debes seleccionar un rango')
    .oneOf(weightOptions.map((el) => el.id), 'Debes seleccionar un rango'),
});

function handleContinue() {
  const formValues = {
    brand: brand.value,
    model: model.value,
    condition: condition.value,
    weight: weight.value,
    subcategoryId: subcategoryId.value,
    categoryId: categoryId.value,
  };

  emit('continue', formValues);
}

watch(categoryId, (newVal) => {
  subcategoriesApi.index(newVal).then((response) => {
    subcategories.value = response.data.subcategories;
    subcategoryId.value = 0;
  });
});

onMounted(() => {
  if (categoryId.value !== 0) {
    subcategoriesApi.index(categoryId.value).then((response) => {
      subcategories.value = response.data.subcategories;
    });
  }
});

</script>
<template>
  <p class="text-center text-lg font-medium leading-6 text-z-gray-800">
    Entréganos más características de tu producto
  </p>
  <v-form
    v-slot="{ meta, handleSubmit, errors }"
    :validation-schema="formRules"
  >
    <div class="flex flex-col space-y-6">
      <div
        v-if="!isEmpty(errors) && meta.touched"
        class="flex h-12 items-center justify-center rounded-lg bg-z-cerise-50 px-4 py-2 text-center text-sm text-z-red"
      >
        Ingresa los datos para continuar
      </div>
      <div class="flex flex-col space-y-4">
        <base-select
          v-model="categoryId"
          name="category"
          label="Categoría"
          hide-label
          :options="categories"
          placeholder="Categoría"
          required
          :error="!!errors.category && meta.touched"
        />
        <base-select
          v-model="subcategoryId"
          name="subcategory"
          label="Subcategoría"
          hide-label
          :options="subcategories"
          placeholder="Subcategoría"
          required
          :disabled="!categoryId"
          :error="!!errors.subcategory && meta.touched"
        />
        <base-input
          v-model="brand"
          name="brand"
          placeholder="Marca"
          label="Marca"
          :error="!!errors.brand && meta.touched"
        />
        <base-input
          v-model="model"
          name="model"
          placeholder="Modelo"
          label="Modelo"
          :error="!!errors.model && meta.touched"
        />
        <base-select
          v-model="condition"
          name="condition"
          label="Estado del producto"
          hide-label
          :options="conditionOptions"
          placeholder="Estado del producto"
          required
          :error="!!errors.condition && meta.touched"
        />
        <base-select
          v-model="weight"
          name="weight"
          label="Peso (kg)"
          hide-label
          :options="weightOptions"
          placeholder="Peso (kg)"
          required
          :error="!!errors.weight && meta.touched"
        />
      </div>
      <base-button
        class="justify-end gap-2"
        type="button"
        @click="handleSubmit($event, handleContinue)"
      >
        {{ summaryMode ? 'Ver resumen' : 'Continuar' }}
        <img src="@/assets/icons/arrow-small-right.svg">
      </base-button>
    </div>
  </v-form>
</template>
