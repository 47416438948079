<script setup lang="ts">
import { computed, ref, inject, onMounted } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { reverse, sortBy } from 'lodash';
import { notificationsApi } from '@/api/notifications';
import NotificationIconPath from '@/assets/icons/notification.svg';
import NotificationPingIconPath from '@/assets/icons/notification-ping.svg';
import NotificationCard from './notification-card.vue';
import { useActionCable } from '../hooks/actioncable';
import { convertKeys } from '../utils/case-converter';

const user = inject<User>('user');

const notifications = ref<Notification[]>([]);
const sortedNotifications = computed(() => reverse(sortBy(notifications.value, 'createdAt')));

const unread = computed(() => notifications.value.some((notification) => !notification.read));

function handleDelete(id:number) {
  notifications.value = notifications.value.filter(
    (notification:Notification) => notification.id !== id,
  );
}
function handleRead(id:number) {
  const notificationIndex = notifications.value.findIndex((notification) => notification.id === id);
  if (notificationIndex !== -1) {
    notifications.value[notificationIndex].read = true;
  }
}
if (user) {
  useActionCable(
    { channel: 'NotificationsChannel', id: user.id },
    {
      received(eventNewResource:Record<string, unknown>) {
        const newNotification = convertKeys(eventNewResource, 'camelize');

        notifications.value.push(newNotification as unknown as Notification);
      },
    },
  );
}

onMounted(() => {
  notificationsApi.index().then((response) => {
    notifications.value = response.data.notifications;
  });
});
</script>
<template>
  <Popover
    v-slot="{ open }"
  >
    <PopoverButton
      class="relative flex h-8 w-8 shrink-0 items-center justify-center rounded-full outline-none transition"
      :class="{ 'bg-z-gray-100': open }"
    >
      <img
        :src="unread ? NotificationPingIconPath : NotificationIconPath"
        class="h-6 w-6 shrink-0"
      >
    </PopoverButton>
    <PopoverPanel
      class="absolute left-0 mt-4 flex w-full flex-col divide-y divide-z-gray-100 overflow-y-scroll bg-white md:left-auto md:mt-2 md:max-h-80 md:max-w-md md:rounded md:border md:border-z-gray-200 md:shadow-md"
      :class="user?.partnerAccountId ? 'h-[calc(100vh-64px-64px)]' : 'h-[calc(100vh-64px-64px-40px)]'"
      as="div"
    >
      <notification-card
        v-for="notification in sortedNotifications"
        :key="notification.id"
        :notification="notification"
        @delete="handleDelete(notification.id)"
        @read="handleRead(notification.id)"
      />
      <div
        v-if="notifications.length === 0"
        class="flex h-48 flex-col items-center justify-center space-y-4"
      >
        <img
          src="@/assets/icons/notification-gray.svg"
          class="w-16"
        >
        <p class="text-z-gray-800">
          No tienes nuevas notificaciones
        </p>
      </div>
    </PopoverPanel>
  </Popover>
</template>
