<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface Props {
  type: 'alert' | 'notice' | 'error',
  message: string,
}

defineProps<Props>();

const show = ref(false);
const duration = 5000;

onMounted(() => {
  show.value = true;
  setTimeout(() => {
    show.value = false;
  }, duration);
});
</script>
<template>
  <Transition>
    <div
      v-if="show"
      class="mx-auto rounded-full bg-z-gray-200/80 p-3 text-center text-sm"
    >
      <p>{{ message }}</p>
    </div>
  </Transition>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
