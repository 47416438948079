<script setup lang=ts>
import { camelCase } from 'lodash';
import format from 'date-fns/format';

interface Props {
  address: AddressData
  products?: Product[]
}

defineProps<Props>();

function handleBack() {
  window.location.href = '/perfil/direcciones';
}
</script>
<template>
  <the-titlebar
    :title="address.displayName"
    @back="handleBack"
  />
  <div class="space-y-1 px-6 py-3">
    <p
      v-if="address.alias"
      class="w-fit rounded bg-z-gray-100 p-2 text-xs"
    >
      {{ address.alias }}
    </p>
    <p class="line-clamp-1 text-z-gray-800">
      {{ address.displayName }}
    </p>
  </div>
  <div class="border-t-2 border-z-gray-100 px-6 py-3">
    <p class="font-medium text-z-gray-900">
      {{ products?.length ?? 0 }} {{ products?.length == 1 ? 'producto' : 'productos' }}
    </p>
  </div>
  <div
    v-for="product in products"
    :key="product.id"
    class="flex w-full space-x-4 border-y border-z-gray-100 p-6"
  >
    <img
      :src="product.productPictures[0].picture.webpSm.url"
      class="h-24 w-24 rounded-lg border border-gray-100 object-cover"
    >
    <div class="flex w-full flex-col space-y-3 text-start">
      <div class="flex w-full items-center justify-between">
        <p class="w-fit rounded bg-z-turquoise-50 px-2 py-0.5 text-xs text-z-turquoise-600">
          {{ $t(`product.condition.${ camelCase(product.condition) }`) }}
        </p>
        <p class="text-xs text-z-gray-900">
          {{ format(new Date(product.createdAt), "d 'de' MMM") }}
        </p>
      </div>
      <p class="line-clamp-1 font-medium">
        {{ product.name }}
      </p>
      <p class="line-clamp-2 text-sm text-z-gray-800">
        {{ product.description }}
      </p>
      <a
        class="text-sm font-medium text-z-gray-800 underline underline-offset-2"
        :href="`/productos/${product.id}`"
      >
        ver más
      </a>
    </div>
  </div>
  <div class="fixed bottom-0 w-full flex-col bg-white px-6 py-2">
    <base-button
      class="w-full"
      :href="`/perfil/direcciones/${address.id}/editar`"
    >
      Editar dirección
    </base-button>
  </div>
</template>

