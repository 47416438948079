import { api } from './index';

export const partnerAccountsApi = {
  create(userId:number) {
    const path = '/api/internal/partner_accounts';

    return api({
      method: 'post',
      url: path,
      data: { userId },
    });
  },
};
