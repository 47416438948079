<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue';
import { camelCase } from 'lodash';
import { generateURL } from '@/utils/generate-url';
import { addressesApi } from '@/api/addresses';
import { partnerAccountsApi } from '@/api/partner-accounts';
import { scrollToTop } from '@/utils/scroll-to-top';
import { currency } from '@/utils/currency';
import { useStore } from '../store/store';
import * as subcategoriesApi from '../api/subcategories';
import { productsApi, type ProductFormData } from '../api/products';
import NewProductStep1 from './new-product-step-1.vue';
import NewProductStep2 from './new-product-step-2.vue';
import NewProductStep3 from './new-product-step-3.vue';
import NewProductStep4 from './new-product-step-4.vue';
import NewProductStep5 from './new-product-step-5.vue';
import newProductSuccessModal from './new-product-success-modal.vue';

interface Props {
  categories: Category[]
  productRequestId?: number
}
const props = defineProps<Props>();

const store = useStore();

const STEPS = [
  { title: 'Nombre del producto', progressBarClass: 'col-span-1' },
  { title: 'Tipo de envío', progressBarClass: 'col-span-2' },
  { title: 'Categorización', progressBarClass: 'col-span-3' },
  { title: 'Descripción', progressBarClass: 'col-span-4' },
  { title: 'Precio y ubicación', progressBarClass: 'col-span-5' },
];
const currentStep = ref(0);

const shippingMethods = [
  { id: 'partner_delivery', name: 'El socio lo entrega' },
  { id: 'client_pickup', name: 'El cliente lo recoge' },
];

const user = inject<User>('user');

const subcategories = ref<Subcategory[]>([]);
const categoryId = ref();
const subcategoryId = ref();
const name = ref();
const brand = ref();
const model = ref();
const condition = ref();
const weight = ref();
const description = ref();
const dailyPrice = ref();
const deposit = ref();
const shippingMethodIds = ref<string[]>([]);
const pictures = ref<File[]>([]);
const addressId = ref();
const address = ref();
const minRange = ref();
const maxRange = ref();

const loading = ref(false);
const error = ref(false);

const showSuccessMessage = ref(false);
const summaryMode = ref(false);

const showProductRequestModal = ref(false);
const alreadyShownModal = ref(false);
function toggleProductRequestModal() {
  if (!alreadyShownModal.value) {
    showProductRequestModal.value = !showProductRequestModal.value;
  }
}

watch(categoryId, (newVal) => {
  subcategoriesApi.index(newVal).then((response) => {
    subcategories.value = response.data.subcategories;
  });
});

onMounted(() => {
  if (categoryId.value) {
    subcategoriesApi.index(categoryId.value).then((response) => {
      subcategories.value = response.data.subcategories;
    });
  }
});

onMounted(() => {
  store.resetProductFormData();
});

watch(currentStep, (newVal) => {
  // eslint-disable-next-line no-magic-numbers
  if (newVal === 5) {
    addressesApi.get(addressId.value).then((response) => {
      address.value = response.data.address;
    });
  }
});

async function handleClick() {
  const formValues = {
    partnerAccountId: user?.partnerAccountId,
    subcategoryId: subcategoryId.value,
    name: name.value,
    brand: brand.value,
    model: model.value,
    condition: condition.value as string,
    weight: weight.value as string,
    description: description.value,
    dailyPrice: dailyPrice.value,
    deposit: deposit.value,
    shippingMethod: shippingMethodIds.value.length === 1 ? shippingMethodIds.value[0] : 'both',
    addressId: addressId.value,
    productRequestId: props.productRequestId,
    minRange: minRange.value,
    maxRange: maxRange.value,
  };
  try {
    if (!formValues.partnerAccountId) {
      formValues.partnerAccountId = await partnerAccountsApi.create(user?.id || 0).then(
        (response) => response.data.partnerAccount.id,
      );
    }
    loading.value = true;
    await productsApi.create(formValues, pictures.value);
    showSuccessMessage.value = true;
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
    store.resetProductFormData();
  }
}

function handleStepBack() {
  currentStep.value -= 1;
  scrollToTop();
}

function handleBack() {
  window.location.href = '/como_subir';
}

function nextStep() {
  const formValues = {
    subcategoryId: subcategoryId.value,
    name: name.value,
    brand: brand.value,
    model: model.value,
    condition: condition.value as string,
    weight: weight.value as string,
    description: description.value,
    dailyPrice: dailyPrice.value,
    deposit: deposit.value,
    shippingMethodIds: shippingMethodIds.value,
    addressId: addressId.value,
    productRequestId: props.productRequestId,
    minRange: minRange.value,
    maxRange: maxRange.value,
    categoryId: categoryId.value,
    pictures: pictures.value,
  };

  store.setProductFormData(formValues);
  if (summaryMode.value) {
    currentStep.value = 5;
  } else {
    currentStep.value += 1;
  }

  scrollToTop();
}

function goToStep(step:number) {
  currentStep.value = step;
}

function handleStep1(newName:string) {
  name.value = newName;
  nextStep();
}

function handleStep2(formValues: { shippingMethodIds: string[], addressId: number }) {
  shippingMethodIds.value = formValues.shippingMethodIds;
  addressId.value = formValues.addressId;
  nextStep();
}

// eslint-disable-next-line complexity
function handleStep3(formValues:Partial<ProductFormData>) {
  categoryId.value = formValues.categoryId ?? 0;
  subcategoryId.value = formValues.subcategoryId ?? 0;
  weight.value = formValues.weight ?? '';
  condition.value = formValues.condition ?? '';
  brand.value = formValues.brand ?? '';
  model.value = formValues.model ?? '';
  nextStep();
}

function handleStep4(formValues: { newDescription: string, newPictures: File[] }) {
  description.value = formValues.newDescription;
  pictures.value = formValues.newPictures;
  nextStep();
}

// eslint-disable-next-line complexity
function handleStep5(formValues:Partial<ProductFormData>) {
  dailyPrice.value = formValues.dailyPrice ?? 0;
  deposit.value = formValues.deposit ?? 0;
  minRange.value = formValues.minRange ?? 0;
  maxRange.value = formValues.maxRange ?? 0;

  if (props.productRequestId && !alreadyShownModal.value) {
    toggleProductRequestModal();
  } else {
    summaryMode.value = true;
    nextStep();
  }
}

function handleConfirm() {
  toggleProductRequestModal();
  alreadyShownModal.value = true;
  summaryMode.value = true;
  nextStep();
}

</script>
<template>
  <the-navbar class="hidden md:block" />
  <div class="absolute top-0 flex h-full w-full flex-col bg-white md:relative md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
    <the-titlebar
      title="Sube tus productos"
      class="md:rounded"
      @back="handleBack"
    />
    <template
      v-if="currentStep === 5"
    >
      <div class="border-b-2 border-t border-z-gray-100 px-6 py-4">
        <p class="text-xl font-bold text-z-gray-800">
          Resumen
        </p>
        <p class="pt-6 text-z-gray-800">
          Revisa con cuidado, si todo está bien sube el producto.
        </p>
      </div>
      <div
        class="border-y-2 border-z-gray-100 px-6 py-4 text-z-gray-900"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            Nombre
          </p>
          <button
            type="button"
            class="font-medium text-z-turquoise-600 underline underline-offset-2"
            @click="goToStep(0)"
          >
            Editar
          </button>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Nombre del producto
          </p>
          <p class="mt-1">
            {{ name }}
          </p>
        </div>
      </div>
      <div
        class="border-y-2 border-z-gray-100 px-6 py-4 text-z-gray-900"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            Envío
          </p>
          <button
            type="button"
            class="font-medium text-z-turquoise-600 underline underline-offset-2"
            @click="goToStep(1)"
          >
            Editar
          </button>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Tipo de envío
          </p>
          <div
            class="mt-1"
          >
            <p
              v-for="shippingMethodId in shippingMethodIds"
              :key="shippingMethodId"
            >
              {{ shippingMethods.find(so => so.id === shippingMethodId)?.name }}
            </p>
          </div>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Dirección
          </p>
          <p class="mt-1">
            {{ address.displayName }}
          </p>
        </div>
        <base-tip
          title="Mi dirección"
          class="mt-3"
        >
          <template #icon>
            <img src="@/assets/icons/pin.svg">
          </template>
          La dirección no será compartida hasta que el usuario arriende el producto.
        </base-tip>
      </div>
      <div
        class="border-y-2 border-z-gray-100 px-6 py-4 text-z-gray-900"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            Categorización
          </p>
          <button
            type="button"
            class="font-medium text-z-turquoise-600 underline underline-offset-2"
            @click="goToStep(2)"
          >
            Editar
          </button>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Categoría
          </p>
          <p class="mt-1">
            {{ categories.find(cat => cat.id == categoryId)?.name }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Subcategoría
          </p>
          <p class="mt-1">
            {{ subcategories.find(subcat => subcat.id == subcategoryId)?.name }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Marca
          </p>
          <p class="mt-1">
            {{ brand }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Modelo
          </p>
          <p class="mt-1">
            {{ model }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Estado
          </p>
          <p class="mt-1">
            {{ $t(`product.condition.${camelCase(condition as string)}`) }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Peso
          </p>
          <p class="mt-1">
            {{ $t(`product.weight.${camelCase(weight as string)}`) }}
          </p>
        </div>
      </div>
      <div
        class="border-y-2 border-z-gray-100 px-6 py-4 text-z-gray-900"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            Descripción
          </p>
          <button
            type="button"
            class="font-medium text-z-turquoise-600 underline underline-offset-2"
            @click="goToStep(3)"
          >
            Editar
          </button>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Descripción del producto
          </p>
          <p class="mt-1">
            {{ description }}
          </p>
        </div>
        <div class="mt-6 w-full">
          <p class="font-medium">
            Fotos
          </p>
          <div class="mt-2 grid grid-cols-2 gap-4">
            <img
              v-for="picture, index in pictures"
              :key="index"
              :src="generateURL(picture)"
              class="aspect-square w-full rounded-lg object-cover"
            >
          </div>
        </div>
      </div>
      <div
        class="border-t-2 border-z-gray-100 px-6 py-4 text-z-gray-900"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            Precio y ubicación
          </p>
          <button
            type="button"
            class="font-medium text-z-turquoise-600 underline underline-offset-2"
            @click="goToStep(4)"
          >
            Editar
          </button>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Precio de arriendo por día
          </p>
          <p class="mt-1">
            {{ currency(dailyPrice) }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Garantía
          </p>
          <p class="mt-1">
            {{ currency(deposit) }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Mínima cantidad de días por arriendo
          </p>
          <p class="mt-1">
            {{ minRange }} {{ minRange > 1 ? 'días' : 'día' }}
          </p>
        </div>
        <div class="mt-6">
          <p class="font-medium">
            Máxima cantidad de días por arriendo
          </p>
          <p class="mt-1">
            {{ maxRange > 0 ? maxRange : 'Sin máximo' }} {{ maxRange > 1 ? 'días' : 'día' }}
          </p>
        </div>
        <base-button
          class="mt-8 w-full gap-2"
          type="button"
          :disabled="loading"
          @click="handleClick()"
        >
          Subir producto
          <img src="@/assets/icons/arrow-small-right.svg">
        </base-button>
      </div>
    </template>
    <template v-else>
      <div class="sticky top-16 z-10">
        <div
          class="grid h-0.5 w-full shrink-0 bg-z-turquoise-100"
          :class="`grid-cols-5`"
        >
          <div
            class="h-full w-full bg-z-turquoise-600"
            :class="STEPS[currentStep].progressBarClass"
          />
        </div>
        <div class="relative flex h-[84px] shrink-0 flex-col items-center justify-center bg-z-turquoise-50 text-z-turquoise-600 shadow-sm">
          <button
            v-if="currentStep != 0"
            class="absolute left-24"
            @click="handleStepBack"
          >
            <img
              src="@/assets/icons/chevron-left-turquoise.svg"
              class="h-8"
            >
          </button>
          <p class="font-semibold">
            {{ currentStep + 1 }} de {{ STEPS.length }}
          </p>
          <p>{{ STEPS[currentStep].title }}</p>
        </div>
      </div>
      <div class="mx-6 mt-4 flex flex-col space-y-6">
        <new-product-step-1
          v-if="currentStep === 0"
          :summary-mode="summaryMode"
          @continue="handleStep1"
        />
        <new-product-step-2
          v-else-if="currentStep === 1"
          :summary-mode="summaryMode"
          @continue="handleStep2"
        />
        <new-product-step-3
          v-else-if="currentStep === 2"
          :summary-mode="summaryMode"
          :categories="categories"
          @continue="handleStep3"
        />
        <new-product-step-4
          v-else-if="currentStep === 3"
          :summary-mode="summaryMode"
          @continue="handleStep4"
        />
        <new-product-step-5
          v-if="currentStep === 4"
          @continue="handleStep5"
        />
      </div>
    </template>
    <new-product-success-modal :is-open="showSuccessMessage" />
    <base-modal
      :open="showProductRequestModal"
      @close="toggleProductRequestModal"
    >
      <div class="flex flex-col">
        <img
          src="@/assets/icons/information-circle.svg"
          class="w-20 self-center"
        >
        <p class="mt-4 text-center font-medium text-z-gray-900">
          Notificación al cliente
        </p>
        <p class="mt-6 text-center text-z-gray-800">
          Revisa bien la información de tu producto,
          luego de publicarlo le enviaremos una notificación al usuario para que pueda adquirirlo de forma rápida.
        </p>
        <base-button
          class="mt-4"
          @click="handleConfirm"
        >
          Aceptar
        </base-button>
      </div>
    </base-modal>
  </div>
</template>
