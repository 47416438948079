<script setup lang="ts">
import { eachDayOfInterval } from 'date-fns';
import { computed, inject, onMounted, ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import camelCase from 'lodash/camelCase';
import { currency } from '@/utils/currency';
import { dateDiffInDays } from '@/utils/date-diff-in-days';
import { formatDate } from '@/utils/format-date';
import { useStore } from '@/store/store';
import { productBookingsApi } from '../api/product-bookings';
import { productsApi } from '../api/products';
import ProductMap from './product-map.vue';
import ProductViewCalendarModal from './product-view-calendar-modal.vue';
import ProductViewShippingModal from './product-view-shipping-modal.vue';
import ProductViewSummaryCard from './product-view-summary-card.vue';
import PartnerDetailsModal from './partner-details-modal.vue';

/* eslint-disable no-magic-numbers */
const CLIENT_COST = inject<number>('clientServiceCost', 20) / 100;

interface Props {
  product: Product;
  partnerAccount: PartnerAccount;
}

const props = defineProps<Props>();

const user = inject<User>('user');

const privateRoutes = inject<boolean>('privateRoutes');

const store = useStore();

const shippingMethods = computed(() => (props.product.shippingMethod === 'both' ?
  ['client_pickup', 'partner_delivery'] :
  [props.product.shippingMethod]));

const selectedShippingMethod = ref(shippingMethods.value.length > 1 ? null : shippingMethods.value[0]);

const showCalendarModal = ref(false);
function toggleCalendarModal() {
  showCalendarModal.value = !showCalendarModal.value;
}

const showShippingModal = ref(false);
function toggleShippingModal() {
  showShippingModal.value = !showShippingModal.value;
}

const selectedAddressId = ref();

function handleShippingMethod(shippingMethod:string) {
  selectedShippingMethod.value = shippingMethod;
}

function handleAddressId(addressId:number) {
  selectedAddressId.value = addressId;
}

const showSummary = ref(false);
const fromSummary = ref(false);

function toggleSummary() {
  showSummary.value = !showSummary.value;
  if (showSummary.value) {
    fromSummary.value = true;
  }
}

const showPartnerDetailsModal = ref(false);
function togglePartnerDetailsModal() {
  showPartnerDetailsModal.value = !showPartnerDetailsModal.value;
}

const showDeleteModal = ref(false);
function toggleDeleteModal() {
  showDeleteModal.value = !showDeleteModal.value;
}

const bookingRange = ref(
  store.newProductBookingRange ?
    store.newProductBookingRange.map((dateString) => new Date(dateString)) :
    undefined,
);
const blocksNumber = computed(() => (
  bookingRange.value ? dateDiffInDays(bookingRange.value[0], bookingRange.value[1]) + 1 : 0
));
const bookingPrice = computed(() => props.product.dailyPrice * blocksNumber.value);
const serviceCost = computed(() => bookingPrice.value * CLIENT_COST);

const disabledDates = computed(() => props.product.disabledDates
  .flatMap((disabledInterval) => eachDayOfInterval({
    start: new Date(disabledInterval.start),
    end: new Date(disabledInterval.end),
  })));

const { isSuccess: isProductBookingCreated, mutate: createProductBooking } = useMutation({
  mutationFn: () => productBookingsApi.create({
    productId: props.product.id,
    startDate: bookingRange.value ? bookingRange.value[0].toISOString() : '',
    endDate: bookingRange.value ? bookingRange.value[1].toISOString() : '',
    amount: bookingPrice.value,
    depositAmount: props.product.deposit,
    serviceCost: serviceCost.value,
    addressId: selectedShippingMethod.value === 'client_pickup' ? props.product.address.id : selectedAddressId.value,
    shippingMethod: selectedShippingMethod.value ?? '',
  }) as Promise<ProductBooking>,
});

function handleBack() {
  window.history.back();
}

const selectedPictureUrl = ref(props.product.productPictures[0].picture.webpLg.url);

function setSelectedPictureUrl(pictureUrl: string) {
  selectedPictureUrl.value = pictureUrl;
}

function handleRange(range:Date[]) {
  bookingRange.value = range;
  toggleCalendarModal();

  if (!user) {
    store.setNewProductBookingRange(range);
    window.location.href = '/usuarios/iniciar_sesion';
  } else if (shippingMethods.value.length > 1) {
    toggleShippingModal();
  } else {
    if (!fromSummary.value) toggleSummary();
  }
}

function handleSummaryBack() {
  window.location.reload();
}

onMounted(() => {
  if (bookingRange.value) {
    if (shippingMethods.value.length > 1) {
      toggleShippingModal();
    } else {
      toggleSummary();
    }
  }
  store.resetNewProductBookingRange();
});

const accountType = computed(() => (props.partnerAccount.userId === user?.id ? 'partnerAccount' : 'clientAccount'));

async function deleteProduct() {
  await productsApi.delete(props.product.id)
    .then(() => {
      window.location.href = '/perfil/productos';
    });
}
</script>
<template>
  <the-navbar class="hidden md:block" />
  <div v-if="showSummary && !!bookingRange">
    <the-titlebar
      title="Realizar la solicitud de reserva"
      @back="handleSummaryBack"
    />
    <div class="divide-y-2 divide-z-gray-100">
      <div class="px-6 py-4">
        <product-view-summary-card
          :product="product"
          :booking-range="bookingRange"
          :shipping-method="shippingMethods[0]"
          :partner-account="partnerAccount"
        />
      </div>
      <div class="flex items-start justify-between space-x-6 px-6 py-4">
        <div class="flex flex-col">
          <p class="font-bold text-z-gray-900">
            Cancelar la reserva
          </p>
          <p class="mt-4">
            Podrás cancelar tu solicitud antes de que el socio la acepte o hayan pasado 6 horas.
          </p>
        </div>
        <img src="@/assets/icons/calendar-delete.svg">
      </div>
      <div class="flex flex-col px-6 py-4">
        <p class="font-bold text-z-gray-900">
          Resumen
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Fecha de arriendo
        </p>
        <p class="text-z-gray-800">
          {{ formatDate(bookingRange[0].toDateString()) }}
          {{ bookingRange[1] ? '-' : '' }}
          {{ bookingRange[1] ? formatDate(bookingRange[1].toDateString()) : '' }}
        </p>
        <button
          class="mt-1 w-fit text-sm text-z-gray-800 underline underline-offset-2"
          @click="toggleCalendarModal"
        >
          Editar
        </button>
        <p class="mt-4 font-medium text-z-gray-900">
          Tipo de entrega
        </p>
        <p class="text-z-gray-800">
          {{ $t(`product.shippingMethod.${accountType}.${camelCase(shippingMethods[0])}`) }}
        </p>
        <button
          v-if="shippingMethods.length > 1"
          class="mt-1 w-fit text-sm text-z-gray-800 underline underline-offset-2"
          @click="toggleShippingModal"
        >
          Editar
        </button>
      </div>
      <div class="px-6 py-4 text-z-gray-900">
        <p class="font-bold">
          Información del precio
        </p>
        <div class="mt-4 flex justify-between">
          <p class="font-medium">
            {{ currency(product.dailyPrice) }} x {{ blocksNumber }} {{ blocksNumber > 1 ? 'días' : 'día' }}
          </p>
          <p>
            {{ currency(bookingPrice) }}
          </p>
        </div>
        <div class="mt-1 flex items-baseline justify-between">
          <p>
            Costo por servicio
          </p>
          <p>
            {{ currency(serviceCost) }}
          </p>
        </div>
      </div>
      <div class="px-6 py-4">
        <p class="font-bold text-z-gray-900">
          Pago
        </p>
        <div class="mt-4 flex flex-col items-center space-y-4 rounded-lg bg-z-blue p-4">
          <img src="@/assets/images/mercadopago.png">
          <p class="text-center text-sm text-z-gray-800">
            Una vez aceptada la solicitud, te avisaremos para que realices el pago con Mercado Pago.
          </p>
        </div>
      </div>
      <div class="px-6 py-4">
        <base-button
          class="w-full"
          @click="createProductBooking"
        >
          Solicitar producto
        </base-button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="md:mx-auto md:my-6 md:max-w-md md:rounded md:bg-white"
  >
    <the-titlebar
      :title="product.name"
      class="md:rounded"
      @back="handleBack()"
    />
    <div
      class="divide-y-2 divide-z-gray-100"
    >
      <div class="flex w-full flex-col px-6 py-4">
        <p class="w-fit self-end rounded bg-z-turquoise-50 px-2 py-0.5 text-sm text-z-turquoise-600">
          {{ $t(`product.condition.${camelCase(product.condition)}`) }}
        </p>
        <img
          :src="selectedPictureUrl"
          class="mt-3 aspect-square w-full rounded-lg object-cover"
        >
        <div class="mt-4 flex gap-4 overflow-x-scroll scrollbar-hide">
          <button
            v-for="picture in product.productPictures"
            :key="picture.id"
            type="button"
            class="aspect-square w-full shrink-0 basis-[30%]"
            @click="setSelectedPictureUrl(picture.picture.webpLg.url)"
          >
            <img
              :src="picture.picture.webpSm.url"
              class="aspect-square w-full rounded-lg object-cover"
            >
          </button>
        </div>
        <p class="mt-4 text-xl font-medium">
          {{ product.name }}
        </p>
        <div class="mt-2 flex justify-between text-z-gray-900">
          <p>Precio:</p>
          <p class="text-xl font-semibold">
            {{ currency(product.dailyPrice) }}/<span class="text-sm font-normal">por día</span>
          </p>
        </div>
        <div class="mt-2 flex justify-between text-z-gray-900">
          <p>Garantía:</p>
          <p class="font-medium">
            {{ currency(product.deposit) }}
          </p>
        </div>
      </div>
      <div class="px-6 py-4">
        <product-map
          :lat="product.address.latitude"
          :lng="product.address.longitude"
          :zoom="13"
        />
        <div class="mt-3 flex items-center justify-center space-x-2">
          <img src="@/assets/icons/location-red.svg">
          <p class="text-z-gray-900">
            <span class="font-medium">{{ product.address.commune }}</span>, {{ product.address.region }}
          </p>
        </div>
      </div>
      <div class="px-6 py-4">
        <div class="flex items-start gap-2 rounded-lg bg-z-turquoise-50 p-3">
          <img src="@/assets/icons/chat-active.svg">
          <div class="flex flex-col">
            <p class="font-medium text-z-gray-900">
              Chat
            </p>
            <p class="text-z-gray-800">
              Estará disponible cuando realices la solicitud de arriendo.
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-between px-6 py-4">
        <div class="flex flex-col">
          <p class="font-bold text-z-gray-900">
            Dueño: {{ partnerAccount.firstName }}
          </p>
          <p class="mt-4 font-medium text-z-gray-800">
            Acerca del dueño
          </p>
          <button
            class="w-fit font-medium text-z-gray-900 underline underline-offset-2"
            @click="togglePartnerDetailsModal"
          >
            Ver más
          </button>
        </div>
        <img
          :src="partnerAccount.pictureUrl"
          class="h-10 w-10 rounded-full object-cover"
        >
      </div>
      <div class="flex flex-col px-6 py-4 text-z-gray-900">
        <p class="font-bold text-z-gray-900">
          Entrega
        </p>
        <p class="mt-4 font-medium text-z-gray-800">
          Opciones de entrega
        </p>
        <ul
          class="mt-2"
        >
          <li
            v-for="shippingMethod in shippingMethods"
            :key="shippingMethod"
            class="ml-5 list-disc text-z-gray-900"
          >
            {{ $t(`product.shippingMethod.${accountType}.${camelCase(shippingMethod)}`) }}.
          </li>
        </ul>
      </div>
      <div
        class="px-6 pb-6 pt-4 text-z-gray-900"
        :class="product.isDeletable ? 'mb-28' : 'mb-16'"
      >
        <p class="font-bold text-z-gray-900">
          Descripción
        </p>
        <p class="mt-4 italic text-z-gray-900">
          {{ product.description }}
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Categoría
        </p>
        <p
          class="flex text-z-gray-800"
        >
          {{ product.category.name }}
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Subcategoría
        </p>
        <p
          class="flex text-z-gray-800"
        >
          {{ product.subcategory.name }}
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Marca
        </p>
        <p
          class="flex text-z-gray-800"
        >
          {{ product.brand }}
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Modelo
        </p>
        <p
          class="flex text-z-gray-800"
        >
          {{ product.model }}
        </p>
        <p class="mt-4 font-medium text-z-gray-900">
          Peso
        </p>
        <p
          class="flex text-z-gray-800"
        >
          {{ $t(`product.weight.${camelCase(product.weight)}`) }}
        </p>
      </div>
      <div
        class="fixed bottom-0 left-0 w-full bg-white px-6 py-3 md:left-auto md:mx-auto md:max-w-md"
      >
        <base-button
          v-if="partnerAccount.userId !== user?.id && !privateRoutes"
          class="w-full"
          @click="toggleCalendarModal"
        >
          Ver disponibilidad
        </base-button>
        <base-button
          v-if="partnerAccount.userId === user?.id"
          class="w-full md:mx-auto md:max-w-md"
          :href="`/productos/${product.id}/editar`"
        >
          Editar
        </base-button>
        <base-button
          v-if="product.isDeletable && partnerAccount.userId === user?.id"
          class="mt-4 w-full md:mx-auto md:max-w-md"
          variant="danger"
          @click="toggleDeleteModal"
        >
          Eliminar producto
        </base-button>
      </div>
    </div>
  </div>
  <product-view-calendar-modal
    :open="showCalendarModal"
    :min-range="product.minRange - 1"
    :max-range="product.maxRange === 0 ? null : product.maxRange - 1"
    :disabled-dates="disabledDates"
    @close="toggleCalendarModal"
    @update-range="(range:Date[]) => handleRange(range)"
  />
  <product-view-shipping-modal
    v-if="shippingMethods.includes('partner_delivery')"
    :open="showShippingModal"
    :shipping-methods="shippingMethods"
    @update:shipping-method="(selectedMethod:string) => handleShippingMethod(selectedMethod)"
    @update:address-id="(addressId:number) => handleAddressId(addressId)"
  />
  <base-modal
    v-if="isProductBookingCreated"
    :open="isProductBookingCreated"
  >
    <div class="flex w-full flex-col items-center">
      <img
        src="@/assets/icons/check-circle.svg"
        class="h-20 w-20"
      >
      <p class="mt-4 font-medium text-z-gray-900">
        Excelente!
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Tu solicitud se ha enviado al socio. Te notificaremos cuando sea respondida.
      </p>
      <base-button
        class="mt-4 w-full"
        href="/"
      >
        Aceptar
      </base-button>
    </div>
  </base-modal>
  <partner-details-modal
    v-if="showPartnerDetailsModal"
    :open="showPartnerDetailsModal"
    :partner-account="partnerAccount"
    :ratings="[]"
    @close="togglePartnerDetailsModal"
  />
  <base-modal
    v-if="showDeleteModal"
    :open="showDeleteModal"
  >
    <div class="flex w-full flex-col items-center">
      <img
        src="@/assets/icons/warning-alt.svg"
        class="h-20 w-20"
      >
      <p class="mt-4 font-medium text-z-gray-900">
        ¿Estás seguro?
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Al eliminar tu producto <span class="font-medium">{{ product.name }}</span>, dejará de estar disponible para la comunidad Zirkular.
      </p>
      <base-button
        class="mt-4 w-full"
        variant="danger"
        @click="deleteProduct"
      >
        Sí, eliminar mi producto
      </base-button>
      <base-button
        class="mt-4 w-full"
        variant="secondary"
        @click="toggleDeleteModal"
      >
        Cambié de opinión
      </base-button>
    </div>
  </base-modal>
</template>
