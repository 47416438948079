<script setup lang="ts">
import { inject, ref } from 'vue';
import { object, string } from 'yup';
import { productRequestsApi } from '../api/product-requests';

const user = inject<User>('user');

const name = ref('');
const description = ref('');
const picture = ref<File>();
const loading = ref(false);
const error = ref(false);

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

const formRules = object().shape({
  name: string().required('Debes ingresar un nombre'),
  description: string().required('Debes ingresar una descripción'),
});

function handleClick() {
  const formValues = {
    clientAccountId: user?.clientAccountId,
    name: name.value,
    description: description.value,
  };
  try {
    loading.value = true;
    productRequestsApi.create(formValues, picture.value);
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
    toggleSuccessModal();
  }
}

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-titlebar
    title="¿No encuentras lo que buscas?"
    @back="handleBack"
  />
  <p class="px-6 py-4 text-center text-z-gray-900">
    Completando el formulario, ¡tu solicitud de producto será vistible para toda la comunidad Zirkular!
  </p>
  <v-form
    v-slot="{ meta, errors, handleSubmit }"
    class=""
    :validation-schema="formRules"
  >
    <div class="flex w-full flex-col space-y-4 px-6">
      <base-input
        v-model="name"
        placeholder="Nombre del producto que buscas"
        label="Nombre del producto que buscas"
        name="name"
        :errors="errors.name && meta.touched"
      />
      <base-input
        v-model="description"
        type="textarea"
        placeholder="Información adicional"
        label="Información adicional"
        name="description"
        :errors="errors.description && meta.touched"
      />
      <div class="space-y-3">
        <p class="font-medium text-z-gray-900">
          Foto referencial
        </p>
        <base-drag-and-drop
          v-model="picture"
          label="Foto de referencia (opcional)"
          hide-label
          name="picture"
          max="1"
          portrait
        />
      </div>
      <base-tip
        title="Notificación"
      >
        Si alguien tiene el producto que buscas, te notificaremos para que puedas arrendarlo rápidamente.
      </base-tip>
    </div>
    <div class="fixed bottom-0 flex w-full flex-col bg-white px-6 py-3">
      <base-button @click="handleSubmit($event, handleClick())">
        Agregar solicitud
      </base-button>
    </div>
  </v-form>
  <base-modal
    v-if="showSuccessModal"
    :open="showSuccessModal"
  >
    <div class="flex flex-col">
      <img
        src="@/assets/icons/check-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center font-medium text-z-gray-900">
        Excelente!
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Su solicitud ha sido agregada de forma exitosa.
      </p>
      <base-button
        href="/necesidades_clientes"
        class="mt-4"
        :variant="user?.hasBankAccount ? 'primary' : 'secondary'"
      >
        Aceptar
      </base-button>
    </div>
  </base-modal>
</template>
