<script setup lang="ts">
import { useStore } from '@/store/store';
import { onBeforeMount, onMounted, provide } from 'vue';

interface Props {
  autoRejectHours: number,
  clientServiceCost: number,
  partnerServiceCost: number,
  privateRoutes: boolean,
  allowedUsers: string[]
  user: User
}

const props = defineProps<Props>();

const store = useStore();

if (store.backUrl &&
  !document.referrer.endsWith(store.backUrl.fromHref) &&
  !document.referrer.endsWith(store.backUrl.toHref)) {
  store.resetBackUrl();
}

onBeforeMount(() => {
  provide('autoRejectHours', props.autoRejectHours);
  provide('clientServiceCost', props.clientServiceCost);
  provide('partnerServiceCost', props.partnerServiceCost);
  provide('privateRoutes', props.privateRoutes);
  provide('allowedUsers', props.allowedUsers);
  provide('user', props.user);
});

function handlePosition(position:GeolocationPosition) {
  store.setUserLocation(position.coords);
}

function handlePositionError() {
  store.resetUserLocation();
}

onMounted(() => {
  navigator.geolocation.getCurrentPosition(handlePosition, handlePositionError);
});

</script>
<template>
  <slot />
</template>
