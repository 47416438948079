<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { notificationsApi } from '@/api/notifications';
import defaultNotificationImagePath from '@/assets/icons/notification-gray.svg';
import { onMounted } from 'vue';

const props = defineProps<{
  notification: Notification;
}>();

const emit = defineEmits<{
  (e: 'delete'): void,
  (e: 'read'): void,
}>();

const { mutate: deleteNotification } = useMutation(() => notificationsApi.delete(props.notification.id), {
  onSuccess: () => {
    emit('delete');
  },
});

const { mutate: markAsRead } = useMutation(() => notificationsApi.update(props.notification.id, { read: true }), {
  onSuccess: () => {
    emit('read');
  },
});

function handleClick() {
  markAsRead();
  if (props.notification.onClickPath) {
    window.location.href = props.notification.onClickPath;
  }
}

onMounted(() => {
  markAsRead();
});
</script>
<template>
  <div
    class="relative flex border-b border-z-gray-100 px-6 py-4"
    :class="{ 'bg-z-turquoise-50': !notification.read }"
  >
    <button
      class="flex h-full w-full items-start space-x-4"
      @click="handleClick()"
    >
      <img
        :src="notification.imagePath ?? defaultNotificationImagePath"
        class="h-12 w-12 shrink-0 rounded-sm object-cover"
      >
      <div class="flex flex-col items-start pr-4">
        <div class="flex items-center space-x-1">
          <div
            v-if="!notification.read"
            class="h-2 w-2 rounded-full bg-z-turquoise-600/40"
          />
          <p
            class="text-left text-xs font-medium text-z-gray-900"
          >
            {{ notification.title }}
          </p>
        </div>
        <p class="text-left text-xs text-z-gray-800">
          {{ notification.message }}
        </p>
        <p class="mt-4 text-xs text-z-gray-600">
          hace {{ formatDistanceToNow(new Date(notification.createdAt)) }}
        </p>
      </div>
    </button>
    <button
      class="absolute right-4 top-1.5 p-2"
      @click="deleteNotification()"
    >
      <img
        src="@/assets/icons/close.svg"
        class="h-4 w-4 opacity-70"
      >
    </button>
  </div>
</template>
