<script setup lang="ts">
import { computed, inject } from 'vue';
import { currency } from '@/utils/currency';
import storeIconUrl from '@/assets/icons/store.svg';
import menuUserIconUrl from '@/assets/icons/menu-user.svg';
import bookingsIconUrl from '@/assets/icons/bookings.svg';
import profileMenuItem from './profile-menu-item.vue';

interface Props {
  monthlyEarnings?: number,
  totalEarnings?: number,
}

withDefaults(defineProps<Props>(), {
  monthlyEarnings: 0,
  totalEarnings: 0,
});

const user = inject<User>('user');
const privateRoutes = inject<boolean>('privateRoutes');
const allowedUsers = inject<string[]>('allowedUsers');

const MENUS = [
  {
    name: 'Mi tienda',
    icon: storeIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/socio', hideOnPrivateRoutes: true },
      { name: 'Mis productos', path: '/perfil/productos' },
      { name: 'Mi historial de tienda', path: '/perfil/productos/historial', hideOnPrivateRoutes: true },
    ],
    partnerOnly: true,
  },
  {
    name: 'Mis arriendos',
    icon: bookingsIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/cliente' },
      { name: 'Mi historial', path: '' },
    ],
    hideOnPrivateRoutes: true,
  },
  {
    name: 'Mis datos',
    icon: menuUserIconUrl,
    subitems: [
      { name: 'Mi cuenta', path: '/perfil/cuenta' },
      { name: 'Mis direcciones', path: '/perfil/direcciones' },
      { name: 'Mis datos bancarios', path: '/perfil/datos_bancarios', partnerOnly: true, hideOnPrivateRoutes: true },
    ],
  },
];

const filteredMenus = computed(() => {
  let finalMenus = MENUS;
  if (!user?.partnerAccountId) {
    finalMenus = finalMenus.filter((menu) => !menu.partnerOnly);
  }

  if (privateRoutes && (!allowedUsers?.includes(user?.email ?? ''))) {
    finalMenus = finalMenus.filter((menu) => !menu.hideOnPrivateRoutes);
  }

  return finalMenus;
});
</script>
<template>
  <the-layout
    hide-navbar
    :active-menu="4"
  >
    <div class="bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Mi perfil"
        hide-back
      />
      <div class="flex flex-col divide-y divide-z-gray-100">
        <div
          v-if="user?.partnerAccountId"
          class="grid grid-cols-2 gap-2 px-6 py-4"
        >
          <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
            <p class="text-xs">
              Total ganado
            </p>
            <p class="mt-1 text-lg font-semibold">
              {{ currency(totalEarnings) }}
            </p>
          </div>
          <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
            <p class="text-xs">
              Ganancia del mes
            </p>
            <p class="mt-1 text-lg font-semibold">
              {{ currency(monthlyEarnings) }}
            </p>
          </div>
        </div>
        <profile-menu-item
          v-for="menuItem, index in filteredMenus"
          :key="index"
          :menu-item="menuItem"
        />
      </div>
      <div class="fixed bottom-20 flex w-full items-center justify-center md:max-w-md">
        <a
          href="/usuarios/cerrar_sesion"
          class="rounded-full border border-z-gray-200 bg-z-gray-100 px-6 py-2"
        >
          Cerrar sesión
        </a>
      </div>
    </div>
  </the-layout>
</template>
