import { api } from './index';

export const notificationsApi = {
  index() {
    const path = '/api/internal/notifications';

    return api({
      method: 'get',
      url: path,
    });
  },
  delete(id: number) {
    const path = `/api/internal/notifications/${id}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
  update(id: number, data:Partial<Notification>) {
    const path = `/api/internal/notifications/${id}`;

    return api({
      method: 'patch',
      url: path,
      data,
    });
  },
};
