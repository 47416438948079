<script setup lang="ts">
import { computed, onBeforeMount, provide, ref } from 'vue';
import { partition, reverse } from 'lodash';
import ProfileProductBookingCard from './profile-product-booking-card.vue';
import ProfileProductBookingPartnerAwaitingActions from './profile-product-booking-partner-awaiting-actions.vue';
import ProfileProductBookingClientAwaitingActions from './profile-product-booking-client-awaiting-actions.vue';
import ProfileProductBookingPaymentActions from './profile-product-booking-payment-actions.vue';
import ProfileProductBookingPartnerPaidActions from './profile-product-booking-partner-paid-actions.vue';
import ProfileProductBookingClientPaidActions from './profile-product-booking-client-paid-actions.vue';

interface Props {
  productBookings?: ProductBooking[]
  approvalTimeLimit: number,
  paymentTimeLimit: number,
  paidTimeLimit: number,
}

const props = withDefaults(defineProps<Props>(), {
  productBookings: () => [],
});

function handleBack() {
  window.history.back();
}

const statusTabs = [
  {
    status: 'awaiting_approval',
    displayName: { partner: 'Solicitados', client: 'Solicitados' },
    showTabs: false,
  },
  {
    status: 'partner_approved',
    displayName: { partner: 'Esperando pago', client: 'Por pagar' },
    showTabs: false,
  },
  {
    status: 'paid',
    displayName: { partner: 'Reservados', client: 'Reservados' },
    showTabs: true,
  },
  {
    status: 'delivered',
    displayName: { partner: 'Entregados', client: 'Entregados' },
    showTabs: false,
  },
  {
    status: 'complete',
    displayName: { partner: 'Completados', client: 'Completados' },
    showTabs: false,
  },
];

const activeTab = ref(0);

function setActiveTab(index:number) {
  activeTab.value = index;
}

const filteredBookings = computed(() => props.productBookings?.filter(
  (pb) => statusTabs[activeTab.value].status === pb.currentStatus),
);

const bookedTab = ref(0);

function setBookedTab(index:number) {
  bookedTab.value = index;
}

const bookedFilteredBookings = computed(() => reverse(partition(filteredBookings.value, 'canceled')));

const accountType = window.location.pathname === '/perfil/solicitudes_reservas/cliente' ? 'client' : 'partner';

onBeforeMount(() => {
  provide('approvalTimeLimit', props.approvalTimeLimit);
  provide('paymentTimeLimit', props.paymentTimeLimit);
  provide('paidTimeLimit', props.paidTimeLimit);
});
</script>
<template>
  <div class="flex flex-col bg-white">
    <the-titlebar
      :title="`${statusTabs[activeTab].displayName[accountType]} (${bookedFilteredBookings[0].length ?? 0})`"
      no-border
      @back="handleBack"
    />
    <div class="sticky top-0 flex space-x-1 overflow-x-scroll border-b border-z-gray-200 px-6 py-3 scrollbar-hide">
      <button
        v-for="tab, index in statusTabs"
        :key="index"
        class="h-8 shrink-0 rounded px-3"
        :class="activeTab === index ? 'bg-z-turquoise-50 text-z-turquoise-600' : 'bg-z-gray-25 text-z-gray-600'"
        @click="setActiveTab(index)"
      >
        {{ tab.displayName[accountType] }}
      </button>
    </div>
    <div
      v-if="filteredBookings.length === 0"
      class="flex w-full flex-col items-center gap-6 px-6 py-4"
    >
      <img
        src="@/assets/icons/task-search.svg"
        class="h-16 w-16"
      >
      <p>No hay elementos aquí</p>
    </div>
    <template v-else>
      <div
        v-if="statusTabs[activeTab].showTabs"
        class="grid grid-cols-2"
      >
        <button
          class="h-12 border-b text-sm"
          :class="bookedTab === 0 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setBookedTab(0)"
        >
          Aceptados
        </button>
        <button
          class="h-12 border-b text-sm"
          :class="bookedTab === 1 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setBookedTab(1)"
        >
          Cancelados
        </button>
      </div>
      <div
        v-if="bookedFilteredBookings[bookedTab].length === 0"
        class="flex w-full flex-col items-center gap-6 px-6 py-4"
      >
        <img
          src="@/assets/icons/task-search.svg"
          class="h-16 w-16"
        >
        <p>No hay elementos aquí</p>
      </div>
      <div
        v-for="productBooking in bookedFilteredBookings[bookedTab]"
        v-else
        :key="productBooking.id"
        class="px-6"
      >
        <profile-product-booking-card
          :product-booking="productBooking"
          :account-type="accountType"
        />
        <template
          v-if="bookedTab === 0"
        >
          <profile-product-booking-partner-awaiting-actions
            v-if="activeTab === 0 && accountType === 'partner'"
            :product-booking="productBooking"
          />
          <profile-product-booking-client-awaiting-actions
            v-else-if="activeTab === 0 && accountType === 'client'"
            :product-booking="productBooking"
          />
          <profile-product-booking-payment-actions
            v-else-if="activeTab === 1 && accountType === 'client'"
            :product-booking="productBooking"
          />
          <profile-product-booking-partner-paid-actions
            v-else-if="activeTab === 2 && accountType === 'partner'"
            :product-booking="productBooking"
          />
          <profile-product-booking-client-paid-actions
            v-else-if="activeTab === 2 && accountType === 'client'"
            :product-booking="productBooking"
          />
        </template>
      </div>
    </template>
  </div>
</template>
