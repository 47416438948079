<script setup lang="ts">
import { inject, ref } from 'vue';
import menuHomeUrl from '@/assets/icons/menu-home.svg';
import menuHomeActiveUrl from '@/assets/icons/menu-home-active.svg';
import menuLineUrl from '@/assets/icons/menu-line.svg';
import menuLineActiveUrl from '@/assets/icons/menu-line-active.svg';
import bagAddUrl from '@/assets/icons/bag-add.svg';
import bagAddActiveUrl from '@/assets/icons/bag-add-active.svg';
import userUrl from '@/assets/icons/user.svg';
import userActiveUrl from '@/assets/icons/user-active.svg';
import chatUrl from '@/assets/icons/chat.svg';
import chatActiveUrl from '@/assets/icons/chat-active.svg';
import defaultProfileUrl from '@/assets/icons/default-profile.svg';

interface Props {
  activeItem?: number;
}
const props = withDefaults(defineProps<Props>(), {
  activeItem: -1,
});

const user = inject<User>('user');

const activeIndex = ref(props.activeItem);

function goToHome() {
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

function goToPartnerStart() {
  const pathnames = ['/productos/crear', '/como_subir'];
  if (!pathnames.includes(window.location.pathname)) {
    window.location.href = '/como_subir';
  }
}

function goToProfile() {
  const path = user ? '/perfil' : '/usuarios/iniciar_sesion';

  if (window.location.pathname !== '/perfil') {
    window.location.href = path;
  }
}

function goToChats() {
  if (window.location.pathname !== '/chats') {
    window.location.href = '/chats';
  }
}

function goToMenu() {
  if (window.location.pathname !== '/menu') {
    window.location.href = '/menu';
  }
}

const MENU_ITEMS = [
  {
    iconNormal: menuHomeUrl,
    iconActive: menuHomeActiveUrl,
    action: goToHome,
  },
  // {
  //   iconNormal: menuLineUrl,
  //   iconActive: menuLineActiveUrl,
  //   action: goToMenu,
  // },
  {
    iconNormal: bagAddUrl,
    iconActive: bagAddActiveUrl,
    action: goToPartnerStart,
  },
  // {
  //   iconNormal: chatUrl,
  //   iconActive: chatActiveUrl,
  //   action: goToChats,
  // },
  {
    iconNormal: user ? user.picture.webpSm.url || defaultProfileUrl : userUrl,
    iconActive: user ? user.picture.webpSm.url || defaultProfileUrl : userActiveUrl,
    action: goToProfile,
  },
];
</script>
<template>
  <div class="fixed bottom-0 flex h-16 w-full border-t border-z-gray-100 bg-white md:hidden">
    <button
      v-for="(item, index) in MENU_ITEMS"
      :key="index"
      class="flex w-full items-center justify-center"
      :class="{ 'border-b-2 border-z-turquoise-600': activeIndex === index }"
      @click="activeIndex = index; item.action()"
    >
      <img
        class="h-6 w-6 rounded-full"
        :class="{ 'box-content border-2 border-z-turquoise-600': index === 4 && activeIndex === index }"
        :src="activeIndex === index ? item.iconActive : item.iconNormal"
      >
    </button>
  </div>
</template>
