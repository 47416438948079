import type { ProductFormData } from '@/api/products';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const useStore = defineStore('store',
  // eslint-disable-next-line max-statements
  () => {
    const newProductBookingRange = ref<Date[]>();
    const productFormData = ref<Partial<ProductFormData>>();
    const backUrl = ref();
    const userLocation = ref<GeolocationCoordinates>();

    function setNewProductBookingRange(data: Date[]) {
      newProductBookingRange.value = data;
    }

    function setProductFormData(data:Partial<ProductFormData>) {
      productFormData.value = data;
    }

    function setBackUrl(newFromHref:string, newToHref:string, newBackName:string) {
      backUrl.value = { fromHref: newFromHref, toHref: newToHref, name: newBackName };
    }

    function setUserLocation(location:GeolocationCoordinates) {
      userLocation.value = location;
    }

    function resetNewProductBookingRange() {
      newProductBookingRange.value = undefined;
    }

    function resetProductFormData() {
      productFormData.value = undefined;
    }

    function resetBackUrl() {
      backUrl.value = undefined;
    }

    function resetUserLocation() {
      userLocation.value = undefined;
    }

    return {
      newProductBookingRange,
      setNewProductBookingRange,
      resetNewProductBookingRange,
      productFormData,
      setProductFormData,
      resetProductFormData,
      backUrl,
      setBackUrl,
      resetBackUrl,
      userLocation,
      setUserLocation,
      resetUserLocation,
    };
  },
  {
    persist: true,
  });

export { useStore };
