import { api } from './index';

export interface RejectMessageFormData {
  reason: string,
  message?: string,
}

export interface ProductBookingFormData {
  productId: number;
  startDate: string;
  endDate: string;
  amount: number;
  depositAmount: number;
  serviceCost: number;
  currentStatus: string;
  addressId: number;
  shippingMethod: string;
  rejectMessage?: RejectMessageFormData;
  canceled: boolean;
  canceledBy: 'partner' | 'client';
}

export const productBookingsApi = {
  create(data: Partial<ProductBookingFormData>) {
    const path = '/api/internal/product_bookings';

    return api({
      method: 'post',
      url: path,
      data,
    }).then(response => response.data.productBooking as ProductBooking);
  },
  update(id:number, data: Partial<ProductBookingFormData>) {
    const path = `/api/internal/product_bookings/${id}`;

    return api({
      method: 'patch',
      url: path,
      data,
    });
  },
};
