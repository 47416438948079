<script setup lang="ts">
import { inject, ref } from 'vue';
import { object, string } from 'yup';
import * as usersApi from '@/api/users';
import { generateURL } from '@/utils/generate-url';
import ProfileAccountRatingsModal from './profile-account-ratings-modal.vue';

interface Props {
  ratings?: UserRating[]
}

defineProps<Props>();

function handleBack() {
  window.history.back();
}

function handleReload() {
  window.location.reload();
}

const user = inject<User>('user');

const firstName = ref(user?.firstName);
const lastName = ref(user?.lastName);
const email = ref(user?.email);
const newPassword = ref();
const passwordConfirmation = ref();
const fakePassword = ref('**********');
const newPicture = ref<File>();

const showSuccessModal = ref(false);
const showRatingsModal = ref(false);

function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

function toggleRatingsModal() {
  showRatingsModal.value = !showRatingsModal.value;
}

const editable = ref(false);

function toggleEditable() {
  editable.value = !editable.value;
}

const loading = ref(false);
const error = ref(false);

const formRules = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  newPassword: string().notRequired(),
  passwordConfirmation: string().notRequired().test((value) => {
    if (newPassword.value) {
      return value === newPassword.value;
    }

    return true;
  }),
});

async function handleClick() {
  let formValues;

  if (newPassword.value) {
    formValues = {
      firstName: firstName.value,
      lastName: lastName.value,
      password: newPassword.value,
      passwordConfirmation: passwordConfirmation.value,
    };
  } else {
    formValues = {
      firstName: firstName.value,
      lastName: lastName.value,
    };
  }

  try {
    loading.value = true;
    await usersApi.update(user?.id as number, formValues, newPicture.value);
    toggleSuccessModal();
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}
</script>
<template>
  <the-navbar class="hidden md:block" />
  <div class="bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
    <the-titlebar
      title="Mi cuenta"
      @back="handleBack"
    />
    <v-form
      v-slot="{ handleSubmit, errors, meta }"
      :validation-schema="formRules"
    >
      <div class="flex w-full flex-col p-6">
        <div>
          <p class="font-medium text-z-gray-700">
            Ingresa tus datos
          </p>
          <div class="mt-4 flex space-x-2">
            <div class="mr-1 mt-1 h-12 w-12">
              <base-file-input
                v-if="editable"
                v-model="newPicture"
                name="newPicture"
                class="h-12 w-12 cursor-pointer rounded-full transition hover:opacity-75"
                accept="image/*"
                capture="user"
              >
                <img
                  v-if="newPicture"
                  :src="generateURL(newPicture as File)"
                  class="h-12 w-12 rounded-full object-cover"
                >
                <img
                  v-else-if="user?.picture.webpSm.url"
                  :src="user?.picture.webpSm.url"
                  class="h-12 w-12 rounded-full object-cover"
                >
                <img
                  v-else
                  src="@/assets/icons/default-profile.svg"
                  class="h-12 w-12 rounded-full object-cover"
                >
              </base-file-input>
              <div
                v-else
              >
                <img
                  v-if="user?.picture.webpSm.url"
                  :src="user?.picture.webpSm.url"
                  class="h-12 w-12 rounded-full object-cover"
                >
                <img
                  v-else
                  src="@/assets/icons/default-profile.svg"
                  class="h-12 w-12 rounded-full object-cover"
                >
              </div>
            </div>
            <div class="flex flex-col space-y-2">
              <p class="text-z-gray-900">
                Calificaciones ({{ ratings?.length ?? 0 }})
              </p>
              <base-stars :value="user?.ratingAverage" />
              <button
                class="self-start text-sm text-z-gray-800 underline underline-offset-2"
                type="button"
                @click="toggleRatingsModal"
              >
                Ver más
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 space-y-4">
          <base-input
            v-model="firstName"
            name="firstName"
            label="Nombre"
            placeholder="Nombre"
            :disabled="!editable"
            :error="meta.touched && !!errors.firstName"
          />
          <base-input
            v-model="lastName"
            name="lastName"
            label="Apellido"
            placeholder="Apellido"
            :disabled="!editable"
            :error="meta.touched && !!errors.lastName"
          />
          <base-input
            v-model="email"
            name="email"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            disabled
            type="email"
          />
          <div v-if="editable">
            <base-input
              v-model="newPassword"
              name="newPassword"
              label="Nueva contraseña"
              placeholder="Nueva contraseña"
              :disabled="!editable"
              type="password"
              :error="meta.touched && !!errors.newPassword"
            />
            <p class="mb-4 mt-1.5 text-xs text-z-gray-600">
              Deja el campo en blanco para mantener tu contraseña actual
            </p>
            <base-input
              v-if="newPassword"
              v-model="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirma tu nueva contraseña"
              placeholder="Confirma tu contraseña"
              :disabled="!editable"
              type="password"
              :error="meta.touched && !!errors.passwordConfirmation"
            />
          </div>
          <template v-else>
            <base-input
              v-if="!editable"
              v-model="fakePassword"
              name="fakePassword"
              label="Contraseña"
              placeholder="Contraseña"
              :disabled="!editable"
            />
          </template>
        </div>
      </div>
      <div class="fixed bottom-0 w-full flex-col bg-white px-6 py-2 md:relative">
        <base-button
          v-if="!editable"
          class="w-full"
          type="button"
          @click="toggleEditable"
        >
          Editar
        </base-button>
        <base-button
          v-else
          class="w-full"
          :disabled="!meta.valid"
          @click="handleSubmit($event, handleClick())"
        >
          Guardar
        </base-button>
      </div>
    </v-form>
    <base-modal :open="showSuccessModal">
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/check-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-4 font-medium text-z-gray-900">
          Excelente!
        </p>
        <p class="mt-6 text-center text-z-gray-800">
          Se actualizó de forma exitosa.
        </p>
        <base-button
          class="mt-4 w-full"
          @click="handleReload()"
        >
          Aceptar
        </base-button>
      </div>
    </base-modal>
    <profile-account-ratings-modal
      :is-open="showRatingsModal"
      :ratings="ratings"
      @close="toggleRatingsModal"
    />
  </div>
</template>
