<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  productRequest: ProductRequest
}
defineProps<Props>();

function handleBack() {
  window.location.href = '/necesidades_clientes';
}

const showConfirmModal = ref(false);
function toggleConfirmModal() {
  showConfirmModal.value = !showConfirmModal.value;
}

</script>
<template>
  <the-titlebar
    title="Necesidades de nuestros clientes"
    @back="handleBack"
  />
  <div class="px-6 py-4">
    <img
      :src="productRequest.picture.webpMd.url"
      class="rounded-lg"
    >
    <p class="mt-3 font-medium">
      {{ productRequest.name }}
    </p>
    <p class="text-sm text-z-gray-800">
      {{ productRequest.description }}
    </p>
  </div>
  <div class="fixed bottom-0 flex w-full flex-col px-6 py-3">
    <base-button @click="toggleConfirmModal">
      Lo tengo
    </base-button>
  </div>
  <base-modal
    :open="showConfirmModal"
    @close="toggleConfirmModal"
  >
    <div class="flex flex-col">
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center font-medium text-z-gray-900">
        Importante
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Antes de notificar al cliente,
        necesitamos que subas primero tu producto y lo hagas visible en nuestra plataforma.
      </p>
      <base-button
        :href="`/necesidades_clientes?request_id=${productRequest.id}`"
        class="mt-4"
      >
        Subir producto
      </base-button>
    </div>
  </base-modal>
</template>
