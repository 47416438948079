<script setup lang="ts">
import { computed, inject, ref } from 'vue';

interface ProfileMenuItem {
  name:string,
  icon:string,
  subitems: { name: string, path: string, partnerOnly?: boolean, hideOnPrivateRoutes?: boolean }[]
}

interface Props {
  menuItem: ProfileMenuItem
}

const props = defineProps<Props>();

const user = inject<User>('user');
const privateRoutes = inject<boolean>('privateRoutes');
const allowedUsers = inject<string[]>('allowedUsers');

const open = ref(false);

function toggleOpen() {
  open.value = !open.value;
}

const filteredSubitems = computed(() => {
  let finalSubitems = props.menuItem.subitems;

  if (!user?.partnerAccountId) {
    finalSubitems = finalSubitems.filter((subitem) => !subitem.partnerOnly);
  }

  if (privateRoutes && (!allowedUsers?.includes(user?.email ?? ''))) {
    finalSubitems = finalSubitems.filter((subitem) => !subitem.hideOnPrivateRoutes);
  }

  return finalSubitems;
});
</script>
<template>
  <div
    class="flex flex-col"
  >
    <button
      class="flex justify-between px-6 py-5"
      type="button"
      @click="toggleOpen"
    >
      <div class="flex space-x-2">
        <img
          :src="menuItem.icon"
          class="w-6"
        >
        <p class="text-z-gray-900">
          {{ menuItem.name }}
        </p>
      </div>
      <img
        src="@/assets/icons/direction-up.svg"
        class="w-6"
        :class="{ 'rotate-180': !open }"
      >
    </button>
    <div
      v-if="open"
      class="flex flex-col"
    >
      <a
        v-for="subitem in filteredSubitems"
        :key="subitem.name"
        class="bg-z-turquoise-50 px-14 py-3 text-z-turquoise-600"
        :href="subitem.path"
      >
        {{ subitem.name }}
      </a>
    </div>
  </div>
</template>
