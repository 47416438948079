<script setup lang="ts">
import { productBookingsApi, type ProductBookingFormData } from '@/api/product-bookings';
import { add, formatDistanceToNowStrict, isPast, isToday } from 'date-fns';
import { computed, inject, ref } from 'vue';
import { object, string } from 'yup';
import { formatDate } from '@/utils/format-date';

const CANCEL_OPTIONS = [
  { id: 'made_another_booking', name: 'Hice otro arriendo' },
  { id: 'not_needed_anymore', name: 'Ya no necesito el artículo' },
  { id: 'cannot_reach_partner', name: 'Socio no responde' },
  { id: 'other', name: 'Otro' },
];

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

const paidTimeLimit = inject<number>('paidTimeLimit');
const paidTime = computed(() => add(new Date(props.productBooking.updatedAt), { hours: paidTimeLimit }));

const remainingTime = computed(() => formatDistanceToNowStrict(paidTime.value));
const pastPaidTime = computed(() => isPast(paidTime.value));

const loading = ref(false);
const error = ref(false);

const showCancelModal = ref(false);
const showCancelForm = ref(false);
const showSuccessModal = ref(false);

function toggleCancelModal() {
  showCancelModal.value = !showCancelModal.value;
}
function toggleCancelForm() {
  showCancelForm.value = !showCancelForm.value;
}
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

function handleCancel() {
  toggleCancelModal();
  toggleCancelForm();
}
function handleSuccess() {
  window.location.reload();
}

async function updateBooking(data:Partial<ProductBookingFormData>) {
  try {
    loading.value = true;
    await productBookingsApi.update(props.productBooking.id, data);
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}

const cancelReason = ref('0');
const cancelMessage = ref('');

const formRules = object().shape({
  cancelReason: string().required()
    .oneOf(CANCEL_OPTIONS.map((el) => el.id)),
  cancelMessage: string().notRequired(),
});

function cancelBooking() {
  try {
    updateBooking({
      canceled: true,
      canceledBy: 'client',
      rejectMessage: {
        reason: cancelReason.value,
        message: cancelMessage.value,
      },
    })
      .then(() => toggleSuccessModal());
  } catch (e) {
    error.value = true;
  }
}

const deliveryToday = computed(() => isToday(new Date(props.productBooking.startDate)));
</script>
<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col space-y-3"
    >
      <base-button
        variant="danger"
        size="sm"
        @click="toggleCancelModal"
      >
        Cancelar
      </base-button>
      <base-tip
        v-if="!pastPaidTime"
        title="Cancelar reserva"
        color="red"
        variant="bold"
      >
        <p>Si cancelas ahora, tendrás un castigo de <span class="font-medium">30% del valor</span> del arriendo.</p>
        <a
          class="text-z-gray-600 underline underline-offset-2"
          href="/legal/terminos_y_condiciones/"
        >
          Ver políticas
        </a>
        <template #icon>
          <img
            src="@/assets/icons/warning-alt-2.svg"
            class="h-6 w-6"
          >
        </template>
      </base-tip>
      <base-tip
        v-else
        title="Cancelar reserva"
        color="red"
        variant="bold"
      >
        Sólo tienes <span class="font-semibold">{{ remainingTime }}</span> para cancelar la reserva sin costos asociados.
        <template #icon>
          <img
            src="@/assets/icons/warning-alt-2.svg"
            class="h-6 w-6"
          >
        </template>
      </base-tip>
      <base-tip
        v-if="deliveryToday"
        title="Hoy es la entrega"
        variant="bold"
      >
        Hoy es la entrega de este producto.
        <template #icon>
          <img
            src="@/assets/icons/warning.svg"
            class="h-6 w-6"
          >
        </template>
      </base-tip>
    </div>
    <base-modal
      :open="showCancelModal"
      @close="toggleCancelModal"
    >
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/warning-alt-2.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-center text-lg font-bold text-z-gray-900">
          ¿Estás seguro que deseas cancelar la reserva?
        </p>
        <p class="mt-2 text-center text-z-gray-800">
          Te recordamos que se le devolverá al cliente su dinero de forma íntegra.
        </p>
        <p
          v-if="!pastPaidTime"
          class="mt-2 text-center text-z-gray-800"
        >
          Además, tendrás una calificación mínima por esta reserva.
        </p>
        <base-button
          class="mt-4 w-full"
          @click="handleCancel"
        >
          <p>
            Aceptar
          </p>
        </base-button>
      </div>
    </base-modal>
    <base-modal :open="showSuccessModal">
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/check-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-lg font-bold text-z-gray-900">
          Excelente!
        </p>
        <p class="mt-2 text-z-gray-800">
          Se realizó de manera correcta
        </p>
        <base-button
          class="mt-4 w-full"
          @click="handleSuccess"
        >
          Aceptar
        </base-button>
      </div>
    </base-modal>
    <div
      v-if="showCancelForm"
      class="absolute inset-0 h-full w-full bg-white"
    >
      <the-titlebar
        title="Cancelar reserva"
        @back="toggleCancelForm"
      />
      <div class="flex space-x-4 border-b-2 border-z-gray-100 px-6 pb-6 pt-3">
        <img
          :src="productBooking.productPictures[0].picture.webpSm.url"
          class="h-[72px] w-[72px] rounded-lg border border-z-gray-50 object-cover"
        >
        <div class="flex flex-col">
          <p class="font-medium">
            {{ productBooking.product.name }}
          </p>
          <p class="mt-1 text-xs text-z-gray-800">
            {{ formatDate(productBooking.startDate) }} - {{ formatDate(productBooking.endDate) }}
          </p>
          <div class="mt-3 flex items-center space-x-2">
            <img
              :src="productBooking.partnerAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <p class="text-xs">
              Dueño: {{ productBooking.partnerAccount.firstName }}
            </p>
          </div>
        </div>
      </div>
      <v-form
        v-slot="{ meta, handleSubmit, errors }"
        class="mt-4 px-6"
        :validation-schema="formRules"
      >
        <p class="text-lg font-medium text-z-gray-900">
          Cuéntanos un poco más
        </p>
        <base-select
          v-model="cancelReason"
          placeholder="¿Por qué deseo rechazar?"
          label="¿Por qué deseo rechazar?"
          hide-label
          name="cancelReason"
          :options="CANCEL_OPTIONS"
          class="mt-8"
          :error="meta.touches && !!errors.cancelReason"
        />
        <base-input
          v-model="cancelMessage"
          name="cancelMessage"
          type="textarea"
          label="Comentario adicional"
          placeholder="Comentario adicional"
          class="mt-3"
          :error="meta.touches && !!errors.cancelMessage"
        />
        <base-tip
          title="Cancelar reserva"
          variant="bold"
          class="mt-4"
        >
          Devolveremos el dinero de forma íntegra al cliente.
          <template #icon>
            <img
              src="@/assets/icons/warning.svg"
              class="h-6 w-6"
            >
          </template>
        </base-tip>
        <div class="fixed bottom-3 left-0 w-full px-6">
          <base-button
            class="w-full"
            @click="handleSubmit($event, cancelBooking)"
          >
            Cancelar reserva
          </base-button>
        </div>
      </v-form>
    </div>
  </div>
</template>
