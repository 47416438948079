<script setup lang="ts">
// import partnerFeaturesUrl1 from '@/assets/images/partner-features-1.png';
// import partnerFeaturesUrl2 from '@/assets/images/partner-features-2.png';
// import partnerFeaturesUrl3 from '@/assets/images/partner-features-3.png';
import { inject } from 'vue';

const user = inject<User>('user');

// const FEATURES = [
//   { title: 'Sube tus productos',
//     description: 'Sube todos los productos que ya no usas a través de un formulario bastante simple.',
//     imageUrl: partnerFeaturesUrl1,
//   },
//   { title: 'Monitorea la entrega',
//     description: 'Entrégalos o espera a que lo recoja el cliente, ' +
//     ' de cualquier forma podrás estar seguro de la entrega.',
//     imageUrl: partnerFeaturesUrl2,
//   },
//   { title: 'Recibe tus pagos',
//     description: 'Podrás ver tus pagos y ganancias por cada alquiler de forma rápida y fácil.',
//     imageUrl: partnerFeaturesUrl3,
//   },
// ];
const STEPS = [
  { title: '¿Qué harás zirkular?',
    description: 'Escribe el nombre de tu producto. Recuerda ser claro para que todos puedan identificarte.',
  },
  { title: '¿Dónde te encontramos?',
    description: 'Tú nos cuentas dónde estás y nosotros publicamos tu dirección, además si al Zirkular tu producto, tú lo llevas o deben retirarlo donde nos indiques.',
  },
  { title: '¿Qué tipo de producto publicarás?',
    description: 'Hogar, outdoor, deportes, herramientas… en fin todo cabe en Zirkular, sólo debes buscar la categoría que más refleje tu producto y seleccionarla.',
  },
  { title: 'Ahora que ya te conocemos...',
    description: 'Cuéntanos un poco más sobre tu producto con una breve descripción y añade al menos 3 fotografías.',
  },
  { title: 'Y lo más importante, ¡cuéntanos el valor de tu producto!',
    description: 'Así como también el valor de la garantía por daño o perjuicio y finalmente los días que lo harás Zirkular.',
  },
  { title: '¡Estás a un paso de finalizar!',
    description: 'Revisa que todos los datos estén correctos, esta es la oportunidad para corregirlos y ¡listo, ahora has Zirkular tu producto, gana dinero y sé miembro de la primera comunidad de arriendo entre personas en Chile!',
  },
];
</script>
<template>
  <the-layout
    :active-menu="2"
  >
    <div
      class="w-full md:flex md:flex-col md:justify-between"
      :class="user?.partnerAccountId ? 'md:min-h-[calc(100vh-72px)]' : 'md:min-h-[calc(100vh-112px)]'"
    >
      <!-- <div class="flex w-full flex-col space-y-5 bg-z-turquoise-600 px-6 py-10">
        <p class="text-xl font-semibold text-white">
          Gana dinero alquilando las cosas que ya no usas
        </p>
        <img src="@/assets/images/banner1.png">
        <base-button
          variant="secondary"
          class="bg-z-turquoise-50"
          href="/productos/crear"
        >
          Empieza ahora
        </base-button>
      </div>
      <div class="flex flex-col bg-white px-6 pb-5 pt-4">
        <div class="my-4 flex flex-col space-y-3 text-z-gray-900">
          <p class="text-xl font-medium">
            Empezar a ganar dinero es muy fácil
          </p>
          <p class="text-sm">
            Sube las cosas que ya no usas y empieza a generar una nueva entrada desde tu hogar.
          </p>
        </div>
        <div
          v-for="feature, index in FEATURES"
          :key="index"
          class="my-4"
        >
          <img
            :src="feature.imageUrl"
            class="h-[100px] w-full rounded-lg object-cover object-center"
          >
          <p class="mt-3 font-medium">
            {{ feature.title }}
          </p>
          <p class="mt-1 text-sm text-z-gray-900">
            {{ feature.description }}
          </p>
        </div>
      </div> -->
      <div class="flex flex-col bg-white px-6 py-5 md:px-[12%] md:py-10">
        <div class="mb-4 flex flex-col space-y-3 text-z-gray-900">
          <p class="text-xl font-medium">
            ¿Cómo publicar mi producto?
          </p>
          <p class="text-sm">
            Te demorarás 5 minutos.
          </p>
        </div>
        <div class="md:grid md:grid-cols-3 md:gap-4">
          <div
            v-for="step, index in STEPS"
            :key="index"
            class="flex space-x-3.5 py-4 md:flex-col md:items-center md:space-x-0 md:rounded md:border md:border-z-gray-200 md:p-6"
          >
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-z-turquoise-50 text-sm text-z-turquoise-600 md:bg-z-turquoise-600 md:text-lg md:font-medium md:text-white">
              {{ index + 1 }}
            </div>
            <div class="flex flex-col space-y-1 md:mt-4 md:space-y-2 md:text-center">
              <p class="font-medium text-z-gray-900">
                {{ step.title }}
              </p>
              <p class="text-sm text-z-gray-800">
                {{ step.description }}
              </p>
            </div>
          </div>
        </div>
        <base-button
          href="/productos/crear"
          class="mt-20 hidden w-fit self-center px-24 md:flex"
        >
          <img src="@/assets/icons/plus.svg">
          <p class="ml-1">
            Subir producto
          </p>
        </base-button>
      </div>
      <the-footer class="mb-32 md:bottom-0 md:mb-0" />
      <div class="fixed bottom-16 flex w-full flex-col bg-white px-6 py-2 md:hidden">
        <base-button href="/productos/crear">
          <img src="@/assets/icons/plus.svg">
          <p class="ml-1">
            Subir producto
          </p>
        </base-button>
      </div>
    </div>
  </the-layout>
</template>
