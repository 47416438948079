export const esLocale = {
  product: {
    condition: {
      isNew: 'Nuevo',
      likeNew: 'Semi nuevo',
      used: 'Usado',
      veryUsed: 'Muy usado',
    },
    weight: {
      lt5: 'Menos de 5 kg',
      gt5Lt10: 'Entre 5 y 10 kg',
      gt10Lt20: 'Entre 10 y 20 kg',
      gt20: 'Más de 20 kg',
    },
    status: {
      pending: 'En revisión',
      active: 'Publicado',
      paused: 'Pausado',
      deleted: 'Eliminado',
    },
    shippingMethod: {
      clientAccount: {
        clientPickup: 'Tú debes retirarlo',
        partnerDelivery: 'Te lo entrega el socio',
      },
      partnerAccount: {
        clientPickup: 'El cliente lo retira',
        partnerDelivery: 'Tú lo entregas',
      },
    },
  },
  productBooking: {
    status: {
      awaitingApproval: 'Esperando aprobación',
      awaitingDelivery: 'Por entregar',
      partnerApproved: 'Aprobada',
      clientApproved: 'Contrapropuesta aprobada',
      partnerRejected: 'Rechazada',
      clientRejected: 'Contrapropuesta rechazada',
      autoRejected: 'Rechazada autom.',
      delivered: 'Entregado',
      completed: 'Completado',
    },
  },
  notification: {
    kind: {
      awaitingApproval: 'Nueva solicitud de arriendo',
      partnerApproved: 'Solicitud aceptada',
      clientApproved: 'Contrapropuesta aceptada',
      partnerRejected: 'Solicitud rechazada',
      clientRejected: 'Contrapropuesta rechazada',
      autoRejected: 'Solicitud rechazada automáticamente',
      delivered: 'Producto entregado',
      completed: 'Devolución exitosa',
      awaitingApprovalReminder: 'Recordatorio de solicitud de arriendo',
    },
  },
  bankAccount: {
    kind: {
      savingsAccount: 'Cuenta de ahorro',
      checkingAccount: 'Cuenta corriente',
      sightAccount: 'Cuenta vista',
    },
  },
  frequentlyAskedQuestion: {
    section: {
      general: 'Preguntas generales',
      partner: 'Preguntas de socios',
      client: 'Preguntas de clientes',
    },
  },
};
