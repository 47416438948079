<script setup lang="ts">
import { useStore } from '@/store/store';
import { isEmpty } from 'lodash';
import { ref, watch } from 'vue';
import { object, number } from 'yup';

const emit = defineEmits<{
  (e: 'continue', value: { dailyPrice:number, minRange:number, maxRange:number, deposit:number }): void
}>();

const store = useStore();

const dailyPrice = ref(store.productFormData?.dailyPrice || undefined);
const minRange = ref(store.productFormData?.minRange ?? 1);
const maxRange = ref(store.productFormData?.maxRange ?? 1);
const noMax = ref(store.productFormData?.maxRange === 0);
const deposit = ref(store.productFormData?.deposit || undefined);

const formRules = object().shape({
  minRange: number().min(1).required(),
  maxRange: number().required().min(noMax.value ? 0 : minRange.value),
  dailyPrice: number().required().min(1),
  deposit: number().required().min(0),
});

function handleContinue() {
  const values = {
    dailyPrice: dailyPrice.value ?? 0,
    minRange: minRange.value,
    maxRange: noMax.value ? 0 : maxRange.value,
    deposit: deposit.value ?? 0,
  };

  emit('continue', values);
}

const formValues = {
  minRange: 1,
  maxRange: 1,
  noMax: false,
};

watch(minRange, (newVal) => {
  if (newVal > maxRange.value) {
    maxRange.value = newVal;
  }
});

watch(maxRange, (newVal) => {
  if (newVal < minRange.value) {
    minRange.value = newVal;
  }
});

</script>
<template>
  <p class="text-center text-lg font-medium leading-6 text-z-gray-800">
    ¡Estás a 1 paso de comenzar a hacer Zirkular tus productos!
  </p>
  <v-form
    v-slot="{ meta, handleSubmit, errors }"
    :validation-schema="formRules"
    :initial-values="formValues"
    class="bg-white"
  >
    <div class="flex flex-col space-y-6">
      <div
        v-if="!isEmpty(errors) && meta.touched"
        class="flex h-12 items-center justify-center rounded-lg bg-z-cerise-50 px-4 py-2 text-center text-sm text-z-red"
      >
        Ingresa los datos para continuar
      </div>
      <div class="flex flex-col space-y-4">
        <base-currency-input
          v-model="dailyPrice"
          name="dailyPrice"
          placeholder="Precio de arriendo por día"
          label="Precio de arriendo por día"
          :error="meta.touched && !!errors.dailyPrice"
        />
        <base-currency-input
          v-model="deposit"
          name="deposit"
          placeholder="Garantía"
          label="Garantía"
          :error="meta.touched && !!errors.deposit"
        />
        <base-counter
          v-model="minRange"
          label="Mínima cantidad de días de arriendo"
          :min="1"
          unit="días"
          name="minRange"
        />
        <base-counter
          v-model="maxRange"
          label="Máxima cantidad de días de arriendo"
          :min="1"
          unit="días"
          :disabled="noMax"
          name="maxRange"
        />
        <div class="relative flex items-center space-x-2">
          <v-field
            v-model="noMax"
            name="noMax"
            type="checkbox"
            :value="true"

            class="peer h-5 w-5 appearance-none rounded-[3px] border-2 border-z-turquoise-600 bg-none checked:bg-z-turquoise-600"
          />
          <img
            src="@/assets/icons/check.svg"
            class="pointer-events-none absolute -left-2 top-0.5 opacity-0 peer-checked:opacity-100"
          >
          <label
            for="noMax"
            class="text-z-gray-900"
          >
            Sin máximo de días de arriendo.
          </label>
        </div>
      </div>
      <base-button
        class="fixed bottom-0 mb-2 mt-10 w-full gap-2"
        type="button"
        @click="handleSubmit($event, handleContinue)"
      >
        Ver resumen
        <img src="@/assets/icons/arrow-small-right.svg">
      </base-button>
    </div>
  </v-form>
</template>
