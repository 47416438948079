<script setup lang="ts">
import productCard from './product-card.vue';

interface Props {
  products?: Product[] | null
}
defineProps<Props>();

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout hide-navbar>
    <div class="bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Productos"
        @back="handleBack"
      />
      <div class="p-6">
        <div
          class="mt-4 grid grid-cols-2 gap-4"
        >
          <product-card
            v-for="product in products"
            :key="product.id"
            :product="product"
          />
        </div>
      </div>
    </div>
  </the-layout>
</template>
